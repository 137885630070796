import axios from "axios";
import { setGlobal, getGlobal } from 'reactn';

export default function setFormToken() {

  const CSRFtoken = getGlobal().csrfToken;

  if (CSRFtoken.length < 20) {

    var apiARL = getGlobal().ApiURL + "/user-token/";
    axios.get(apiARL).then(function (response) {
      if (response.data !== null) {
        if (response.data.status === 1) {
          setGlobal({
            csrfToken: response.data.token,
          });
        }
      }
    });
  }


  return false;

}
