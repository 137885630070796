import React from "react"
import { getGlobal } from 'reactn';

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row fit-row">
            <div className="col-12">
              {getGlobal().systemVer}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
