export const HomeCrumbs = [];
export const LogOutCrumbs = [
  {
    title: "Log Out",
    path: "logout",
  },
];
export const FormsCrumbs = [
  {
    title: "Forms",
    path: "forms",
  },
];
export const FormDataCrumbs = [
  {
    title: "Forms",
    path: "view-forms/",
  },
  {
    title: "Form Data",
    path: "form-data",
  },
];
export const DataEntryCrumbs = [
  {
    title: "Forms",
    path: "view-forms/",
  },
  {
    title: "Form Data",
    path: "view-forms/",
  },
  {
    title: "Data Entry",
    path: "data-entry",
  },
];








export const CompetitionCrumbs = [
  {
    title: "Competitions",
    path: "competitions",
  },
];
export const CreateForeverCrumbs = [
  {
    title: "Competitions",
    path: "competitions",
  },
  {
    title: "Create Forever",
    path: "competitions/create-forever",
  },
];
export const ExposureCenterCrumbs = [
  {
    title: "Competitions",
    path: "competitions",
  },
  {
    title: "Exposure Center",
    path: "competitions/exposure-center",
  },
];


export const GalleryCrumbs = [
  {
    title: "Gallery",
    path: "gallery",
  },
];
export const NotFoundCrumbs = [
  {
    title: "404",
    path: "",
  },
];
export const TableCrumbs = [
  {
    title: "Table",
    path: "table",
  },
];
export const FormCrumbs = [
  {
    title: "Forms",
    path: "forms",
  },
];
export const XThusiastCrumbs = [
  {
    title: "Forms",
    path: "forms",
  },
  {
    title: "X-thusiast",
    path: "forms/x-thusiast",
  },
];
