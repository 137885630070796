import React from 'react';
import axios from "axios";
import { setGlobal, getGlobal } from 'reactn';
import { eraseCookie } from '../helpers/Cookies.js';
import Card from "../components/Card";
import { VscSignOut } from "react-icons/vsc";

import Breadcrumb from "../components/Breadcrumb";
import { LogOutCrumbs } from "../utils/BreadcrumbData";

class LogOut extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0
    };
  }

  StateChange = (stateChoice) => {

    this.setState({ isLoading: 1 });

    var Token = getGlobal().Token;
    var CSRFtoken = getGlobal().csrfToken;

    if (stateChoice === 1) {

      const formData = new FormData();      
      formData.append('Token', Token);
      formData.append('CSRFtoken', CSRFtoken);

      const handleLogOut = async e => {

        var apiARL = getGlobal().ApiURL + "/logout/everywhere/";
        const res = await axios.post(apiARL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (res.data.status === 1) {

          eraseCookie('P_PAT_Token');
          localStorage.removeItem("DarkMode");

          setGlobal({
            LoggedIn: 0,
            Token: null,
            csrfToken: "",
            twoFaToken: "",
          });

          window.location.href = "/";

        }

      }
      handleLogOut();

    }

    if (stateChoice === 2) {

      const formData = new FormData();

      formData.append('Token', Token);
      formData.append('CSRFtoken', CSRFtoken);

      const handleLogOut = async e => {

        var apiARL = getGlobal().ApiURL + "/logout/just-here/";
        const res = await axios.post(apiARL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (res.data.status === 1) {

          eraseCookie('P_PAT_Token');
          localStorage.removeItem("DarkMode");

          setGlobal({
            LoggedIn: 0,
            Token: null,
            csrfToken: "",
            twoFaToken: "",
          });

          window.location.href = "/";

        }

      }
      handleLogOut();

    }

  }


  render() {
    return (
      <>
        <Breadcrumb items={LogOutCrumbs} />
        <Card
          title="Where do you want to log out from?"
          subTitle=""
          icon={<VscSignOut />}
        >

          <div className="logout-option" onClick={() => this.StateChange(2)}>This device only</div>
          <div className="logout-option" onClick={() => this.StateChange(1)}>Everywhere</div>

        </Card>
      </>
    )
  }
}
export default LogOut;
