import React from "react";
import { VscSave } from "react-icons/vsc";

function CardFooter(props) {
  return (
    <div className="card-footer">
      {props.children}
      <div className="card-buttons">
        <button className="btn btn-save" type="submit" value="Submit">
          <VscSave /> Save Entry
        </button>
      </div>
    </div>
  );
}

export default CardFooter;
