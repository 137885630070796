import React, { useState } from "react";
import SideMenuData from "../utils/SideMenuData";
import SideMenuSub from "./SideMenuItems";
import { VscMenu } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
//import { VscAccount, VscSettingsGear } from "react-icons/vsc";
//import Collapse from "./Collapse";

const SideMenu = (props) => {
  const [buttonToggle, setbuttonToggle] = useState(true);

  function ButtonClick() {
    setbuttonToggle(!buttonToggle);
  }

  return (
    <>
      <nav className="top-nav">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="top-nav-items">
                <div>
                  <div className="menu-toggle" onClick={ButtonClick}>
                    {buttonToggle ? <VscChromeClose /> : <VscMenu />}
                  </div>
                  <span className="logo"></span>
                </div>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav className={`side-nav ${buttonToggle ? "open" : ""}`}>
        {SideMenuData.map((item, index) => {
          return <SideMenuSub item={item} key={index} />;
        })}
      </nav>
    </>
  );
};

export default SideMenu;
