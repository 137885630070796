import React, { useState } from "react";
import axios from "axios";
import { setGlobal, getGlobal } from 'reactn';

import { eraseCookie } from '../helpers/Cookies.js';

export default function LoginForm(props) {

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(0);
  const [feedbackError, setfeedbackError] = useState(0);
  const [feedbackErrorMsg, setfeedbackErrorMsg] = useState(0);
  const [formLoading, setFormLoading] = useState(0);

  const handleSubmit = async e => {

    e.preventDefault();

    if (formLoading === 0) {

      setFormLoading(1);

      if (username === undefined || username === "") {
        setError(1);
        setFormLoading(0);
        return false;
      } else if (password === undefined || password === "") {
        setError(2);
        setFormLoading(0);
        return false;
      } else {
        loginUser().catch();
        async function loginUser() {

          const CSRFtoken = getGlobal().csrfToken;

          const formData = new FormData();
          formData.append('username', username);
          formData.append('password', password);
          formData.append('CSRFtoken', CSRFtoken);

          var apiARL = getGlobal().ApiURL + "/login/";
          const res = await axios.post(apiARL, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

          if (res.data.status === 0) {

            setfeedbackError(1);
            setFormLoading(0);
            setfeedbackErrorMsg(res.data.feedback);

            eraseCookie('P_PAT_Token');

          } else if (res.data.status === 1) {

            eraseCookie('P_PAT_Token');

            var token = res.data.token;

            setGlobal({ twoFaToken: token });

            props.setViewingForm('2fa');

          } else {
            console.log("UNKNOWN ERROR!");
          }

        }

        return false;
      }

    } else {
      return false;
    }
  }

  return (
    <>
      <div className={["login-container", formLoading === 1 ? "loading" : null].join(" ")}>
        <div className={["login-form", formLoading === 1 ? "loading" : null].join(" ")}>
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>

            {error === 1 ? (<p className="login-feedback">Enter your email</p>) : null}
            {error === 2 ? (<p className="login-feedback">Enter your password?</p>) : null}
            {error === 3 ? (<p className="login-feedback">Unknown Error!</p>) : null}

            {feedbackError === 1 ? (<p className="login-feedback">{feedbackErrorMsg}</p>) : null}

            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className="input-text"
                required
                placeholder="Email"
                onChange={e => {
                  setUserName(e.target.value);
                  setError(0);
                  setfeedbackError(0);
                }}
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={e => {
                  setPassword(e.target.value);
                  setError(0);
                  setfeedbackError(0);
                }}
              />
            </div>
            <div>
              <input type="submit" value="login" className={[formLoading === 1 ? "disabled" : null, "login-submit form-button"].join(' ')} />
            </div>

          </form>
        </div>
      </div>
    </>
  )
}




export function LostPasswordForm(props) {
  return (
    <>
      LostPasswordForm

    </>
  );
}
