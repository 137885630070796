import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb(props) {
  return (
    <section className="breadcrumb">
      <div className="container-fluid">
        <div className="row fit-row">
          <div className="col-12 breadcrumb-col">
            <div>
              <span className="breadcrumb-item">
                {props.items.length ? <Link to="/">Home</Link> : <>Home</>}
              </span>
              {props.items.map((item, index) => (
                <span className="breadcrumb-item" key={index}>
                  {index !== props.items.length - 1 ? (
                    <Link to={`/${item.path}`}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                </span>
              ))}
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
