import React, { useState, useRef, useEffect } from "react";
import { VscTriangleRight } from "react-icons/vsc";

function Collapse(props) {

  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef();
  let open = props.open;

  useEffect(() => {
    let collapseHeight = parentRef.current.scrollHeight;

    open
      ? (parentRef.current.style.height = collapseHeight + "px")
      : setIsOpen((isOpen) => !isOpen);
  }, [open]);

  return (
    <div className="collapse">
      <div
        className={`collapse-toggle ${isOpen ? "" : "collapsed"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.label}
        <VscTriangleRight className="collapse-icon" />
      </div>
      <div
        className="collapse-content-parent"
        ref={parentRef}
        style={
          isOpen
            ? {
              height: "0px",
            }
            : {
              height: parentRef.current
                ? parentRef.current.scrollHeight + "px"
                : "",
            }
        }
      >
        <div className="collapse-content">{props.children}</div>
      </div>
    </div>
  );
}

export default Collapse;
