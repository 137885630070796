export default function serialize(e) {

    const formData = [];

    if (e && "FORM" === e.nodeName) {

        var s;
        var key_name;
        var key_value;
        let field_div;
        for (s = e.elements.length - 1; s >= 0; s -= 1)
            if ("" !== e.elements[s].name)
                switch (e.elements[s].nodeName) {

                    case "INPUT":
                        switch (e.elements[s].type) {
                            case "text":
                            case "tel":
                            case "email":
                            case "hidden":
                            case "password":
                            case "button":
                            case "reset":
                            case "submit":
                                key_name = e.elements[s].name;
                                key_value = encodeURIComponent(e.elements[s].value);
                                formData.push({ name: key_name, value: key_value });
                                break;
                            case "checkbox":
                            case "radio":
                                key_name = e.elements[s].name;
                                field_div = document.getElementById(key_name);
                                key_value = field_div.getAttribute('data-value');
                                
                                formData.push({ name: key_name, value: key_value });
                                break;
                            default:
                                break;
                        }
                        break;
                    case "file":
                        break;
                    case "TEXTAREA":
                        key_name = e.elements[s].name;
                        key_value = encodeURIComponent(e.elements[s].value);
                        formData.push({ name: key_name, value: key_value });
                        break;
                    case "SELECT":
                        switch (e.elements[s].type) {
                            case "select-one":
                                key_name = e.elements[s].name;
                                key_value = encodeURIComponent(e.elements[s].value);
                                formData.push({ name: key_name, value: key_value });
                                break;
                            case "select-multiple":
                                key_name = e.elements[s].name;
                                key_value = encodeURIComponent(e.elements[s].value);
                                formData.push({ name: key_name, value: key_value });
                                break;
                            default:
                                break;
                        }
                        break;
                    case "BUTTON":
                        switch (e.elements[s].type) {
                            case "reset":
                            case "submit":
                            case "button":
                            default:
                                key_name = e.elements[s].name;
                                key_value = encodeURIComponent(e.elements[s].value);
                                formData.push({ name: key_name, value: key_value });
                        }
                        break;
                    default:
                        break;
                }


        return formData;
    }


}
