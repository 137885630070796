import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setGlobal, getGlobal, addCallback } from 'reactn';

import { getCookie } from './helpers/Cookies.js';
import setFormToken from './helpers/setFormToken.js';

import { CgSun } from "react-icons/cg";
import { CgMoon } from "react-icons/cg";

import Login from "./pages/Login";
import LogOut from "./pages/LogOut";
//import Home from "./pages/Home";
import ViewForms from "./pages/ViewForms";
import FormData from "./pages/FormData";
import DataEntry from "./pages/DataEntry";

import Table from "./pages/demo/Table";
import Cards from "./pages/demo/Cards";
import Gallery from "./pages/demo/Gallery";
import { Forms } from "./pages/demo/Forms";
import XThusiastSingle from "./pages/demo/XThusiastSingle";
import XThusiastForms from "./pages/demo/XthusiastTable";

import SideMenu from "./components/SideMenu";
import ToggleSwitch from "./components/ToggleSwitch";

import "./scss/style.scss";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";



let P_PAT_Token = getCookie('P_PAT_Token');
var get_DarkMode = localStorage.getItem("DarkMode");

var loggedInCookie = 0;
if (P_PAT_Token !== null) { loggedInCookie = 1; }


var SavedDarkMode = false;
if (get_DarkMode !== null) { SavedDarkMode = get_DarkMode; }

setGlobal({
  LoggedIn: loggedInCookie,
  Token: P_PAT_Token,
  
  ApiURL: "https://datasuite-api.bright.uk.com",
  cdnURL: "https://datasuite-static.bright.uk.com",
  
  //ApiURL: "https://datasuite-api.dev.bright.uk.com",
  //cdnURL: "https://datasuite-static.dev.bright.uk.com",
  
  //ApiURL: "https://dev-api.fujifilm-collaborators.co.uk",
  //cdnURL: "https://dev-static.fujifilm-collaborators.co.uk",
  
  //ApiURL: "https://dev-api.ffus-optics.com",
  //cdnURL: "https://dev-static.ffus-optics.com",

  //ApiURL: "https://ffus-optics.com",
  //cdnURL: "https://static.ffus-optics.com",

  csrfToken: "",
  twoFaToken: "",
  systemVer: "v1.0.0",
  DarkMode: SavedDarkMode,
});


function App() {

  setFormToken();

  const [darkMode, setDarkMode] = useState(SavedDarkMode);
  const [loggedin, setLoggedin] = useState(getGlobal().LoggedIn);

  addCallback(function () {
    setTimeout(function () {
      setLoggedin(getGlobal().LoggedIn)
    }, 10);
  });


  if (loggedin === 0) {
    return (<><Login /></>);
  }

  if (loggedin === 1) {
    return (
      <>
        <Router>
          <div className="theme" data-theme={darkMode === true || darkMode === "true" ? "dark" : "light"}>
            <SideMenu>
              <ToggleSwitch
                checked={get_DarkMode}
                onChange={
                  (event) => {
                    localStorage.setItem("DarkMode", event.target.checked);
                    setDarkMode(event.target.checked);
                  }
                }
                id="this"
                onIcon={<CgSun />}
                offIcon={<CgMoon />}
              />
            </SideMenu>
            <main className="container-fluid">
              <div className="row fit-row">
                <div className="col-12">
                  <Switch>
                    <Route exact path="/logout/" component={LogOut} />

                    <Route exact path="/" component={ViewForms} />
                    <Route exact path="/view-forms/" component={ViewForms} />
                    <Route exact path="/form-data/" component={ViewForms} />
                    <Route path="/form-data/:slug" component={FormData} />
                    <Route path="/data-entry/:slug" component={DataEntry} />

                    <Route exact path="/table" component={Table} />
                    <Route exact path="/cards" component={Cards} />
                    <Route exact path="/gallery" component={Gallery} />
                    <Route exact path="/forms" component={Forms} />
                    <Route exact path="/forms/x-thusiast" component={XThusiastForms}
                    />
                    <Route exact path="/forms/x-thusiast/single" component={XThusiastSingle}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </Router>
      </>
    );
  }

}

export default App;
