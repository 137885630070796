import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { GalleryCrumbs } from "../../utils/BreadcrumbData";
import Card from "../../components/Card";
import Gallery from "../../components/Gallery";
import { VscFileMedia } from "react-icons/vsc";

const baseURL = window.location.origin;

const GalleryData = [
      {
        id: "1",
        url: baseURL + "/images/img1.jpg",
        fileName: "img1.jpg",
      },
      {
        id: "2",
        url: baseURL + "/images/img2.jpg",
        fileName: "img2.jpg",
      },
      {
        id: "3",
        url: baseURL + "/images/img3.jpg",
        fileName: "img3.jpg",
      },
      {
        id: "4",
        url: baseURL + "/images/img4.jpg",
        fileName: "img4.jpg",
      },
      {
        id: "5",
        url: baseURL + "/images/img5.jpg",
        fileName: "img5.jpg",
      },
      {
        id: "6",
        url: baseURL + "/images/img6.jpg",
        fileName: "img6.jpg",
      },
      {
        id: "7",
        url: baseURL + "/images/img7.jpg",
        fileName: "img7.jpg",
      },
      {
        id: "8",
        url: baseURL + "/images/img8.jpg",
        fileName: "img8.jpg",
      },
      {
        id: "9",
        url: baseURL + "/images/img9.jpg",
        fileName: "img9.jpg",
      },
      {
        id: "10",
        url: baseURL + "/images/img10.jpg",
        fileName: "img10.jpg",
      },
];

function Forms() {
  return (
    <>
      <Breadcrumb items={GalleryCrumbs} />
      <Card title="Images" subTitle="Image gallery example" icon={<VscFileMedia />}>
        <Gallery data={GalleryData}  cols={{default: 4, 1200: 2}} lightbox />
      </Card>
    </>
  );
}
export default Forms;
