import React from "react"
import { Link } from "react-router-dom"
import Breadcrumb from "./Breadcrumb"
import { NotFoundCrumbs } from "../utils/BreadcrumbData"

const NotFound = () => (
  <>
    <Breadcrumb items={NotFoundCrumbs} />
    <div className="card">
      <h1>404 - Not Found!</h1>
      <p><Link to="/">Go Home</Link></p>
    </div>
  </>
);

export default NotFound
