export default function decodeEntities(str) {

  var map = {
    '&amp;': '&',
    '&#038;': "&",
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#8217;': "’",
    '&#8216;': "‘",
    '&#8211;': "–",
    '&#8212;': "—",
    '&#8230;': "…",
    '&#8221;': '”'
  };

  return str.replace(/&[\w\d#]{2,5};/g, function (m) { return map[m]; });

}
